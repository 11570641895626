<template>
  <div class="order-wrapper">
    <progress-bar :activeIndex="2" v-if="!dialogVisible" />
    <progress-bar :activeIndex="3" v-else />
    <div class="container">
      <div class="passenger">
        <div class="info-panel" v-if="dialogVisible">
          <div class="booked-ticket">
            <div class="info-text">
              <span class="info-text-title">支付信息</span>
              <div class="info-text-content">
                <p>
                  请您在新打开的网上银行页面进行支付，支付完成前请不要关闭该窗口
                </p>
                <p>
                  若您的银行付款页面没有打开，请检查并设置您的浏览器“允许弹出”
                </p>
                <p>
                  若您的银行卡或账户已被扣款，但订单没有支付成功，这可能是网络传输问题造成
                </p>
                <p>
                  湖南航空将尽快为您出票，网站会通过短信及邮件告知出票情况，请您保持关注
                </p>
                <p>
                  如遇问题，可拨打湖南航空客服热线
                  <span class="warning">4008337777</span>
                </p>
              </div>
            </div>
          </div>
          <span slot="footer" class="order-footer">
            <span class="order-success" @click="reloadPage">支付成功</span>
            <span class="order-failed" @click="dialogVisible = false"
              >支付失败，继续支付</span
            >
          </span>
        </div>
        <div class="info-panel" v-if="!dialogVisible">
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">订单信息</div>
            </div>
            <div class="booked-ticket-body flex">
              <div class="passenger-info no-padding">
                <div class="book-panel" v-if="order.orderStatus == 'WP'">
                  <div @click="gotoCancelOrder(orderNo)">取消</div>
                </div>
                <el-form
                  class="passenger-input-info"
                  style="margin-top: 10px"
                  label-position="top"
                >
                  <div>
                    <div>
                      <span class="item-title">订单状态</span>
                      <span class="item-value">{{
                        order.orderStatusDesc
                      }}</span>
                    </div>
                    <div>
                      <span class="item-title">订单号</span>
                      <span class="item-value">{{ order.orderNo }}</span>
                    </div>
                    <div>
                      <span class="item-title">预订日期</span>
                      <span class="item-value">{{
                        order.createTime | formatDate
                      }}</span>
                    </div>
                  </div>
                  <!-- <el-row v-for="segment in order.segments" v-bind:key="segment.segmentRef">
                    <el-col :span="4">
                      <el-form-item>
                        <template slot="label">
                          <div class="form-label">去程</div>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item>
                        <template slot="label">
                          <div class="form-label">{{segment.depDatetime}}</div>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <template slot="label">
                          <div class="form-label">{{segment.marketFlightNo}}</div>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <template slot="label">
                          <div class="form-label">{{segment.depAirportName}}</div>
                        </template>
                      </el-form-item>
                    </el-col>
                    <el-col :span="4">
                      <el-form-item>
                        <template slot="label">
                          <div class="form-label">{{segment.arrAirportName}}</div>
                        </template>
                      </el-form-item>
                    </el-col>
                  </el-row>-->
                  <!-- <el-checkbox class="save-often">保存为常用乘机人</el-checkbox> -->
                </el-form>
              </div>
            </div>
          </div>
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">乘机人信息</div>
            </div>
            <div class="booked-ticket-body">
              <div class="passenger-info no-padding">
                <el-form class="passenger-input-info" label-position="top">
                  <div
                    style="display: flex;"
                    v-for="(pass, idx) in order.passengers"
                    v-bind:key="pass.certNo"
                  >
                    <div class="item-index">{{idx + 1}}</div>
                    <div class="order-pass-item">
                      <div>
                        <span class="item-title">{{pass.type | formatType }}</span>
                        <span class="item-value">{{pass.name}}</span>
                      </div>
                      <div>
                        <span class="item-title">{{pass.certType | formatCertType}}</span>
                        <span class="item-value">{{pass.certNo}}</span>
                      </div>
                      <div>
                        <span class="item-title">手机号</span>
                        <span class="item-value">{{order.contact.mobile}}</span>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">联系人</div>
            </div>
            <div class="booked-ticket-body">
              <div class="passenger-info" style="padding: 8px 8px 8px 20px">
                <el-form class="passenger-input-info" label-position="top">
                  <div>
                    <span class="item-title">手机号码</span>
                    <span class="item-value">{{ order.contact.mobile }}</span>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <!-- <div class="booked-ticket">
            <div class="booked-title">
              <div class="title">优惠券</div>
            </div>
            <div class="booked-ticket-body">
              <div class="passenger-info" style="padding: 8px 8px 8px 20px">
                <el-form class="passenger-input-info" label-position="top">
                  <div>
                  </div>
                </el-form>
              </div>
            </div>
          </div> -->
          <div class="booked-ticket" v-if="order.orderStatus=='WP'" style="display:none">
            <div class="booked-title">
              <div class="title">支付方式</div>
            </div>
            <div class="booked-ticket-body radio-type">
              <el-radio v-model="payType" label="alipay">
                <div class="passenger-info">
                  <img
                    src="../../assets/ali-logo.png"
                    width="200"
                    height="100"
                  />
                </div>
              </el-radio>
              <el-radio v-model="payType" label="wechat" style="display: none">
                <div class="passenger-info">
                  <img
                    src="../../assets/wechat-logo.png"
                    width="200"
                    height="120"
                  />
                </div>
              </el-radio>
            </div>
          </div>
          <button
            :class="['next-step', !intervelTime.length ? 'step-disabled' : '' ]"
            @click="payHandler"
            v-if="order.orderStatus == 'WP'"
            :disabled="orderCanPay"
          >
            去支付
            <span style="color: #ffffff" v-if="intervelTime.length > 0"
              >(剩余{{ intervelTime }})</span
            >
          </button>
          <el-popover class="popover" ref="pop" trigger="hover">
            <popover-content />
          </el-popover>
          <el-popover ref="tips" trigger="hover">
            <p class="tips-text">
              婴儿年龄介于14天（含）到2周岁之间；儿童年龄介于2周岁（含）到12周岁之间，成人年龄大于12周岁（含）
            </p>
          </el-popover>
        </div>
        <div class="fee-panel">
          <div class="fee-panel__inner">
            <div class="fee-panel-body">
              <div class="fee-panel-content">
                <fee-panel :orderNo="orderNo"/>
                <div class="fee-panel-group">
                  <!-- {{ fareData }} -->
                  <div v-for="fare in fareData" v-bind:key="fare.title">
                    <!-- <h4>{{fare.title}}票价</h4> -->
                    <ul class="list">
                      <li>
                        <span>{{ fare.title }}</span>
                        <span>¥{{ fare.price }} * {{ fare.passCount }}</span>
                      </li>
                      <li>
                        <span>机建+燃油</span>
                        <span>¥{{ fare.tax }} * {{ fare.passCount }}</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="fee-panel-group">
                  <div>
                    <ul class="list noBefore">
                      <li>
                        <span>航班保险</span>
                        <span>¥0</span>
                      </li>
                      <li>
                        <span>保险优惠减免</span>
                        <span>¥0</span>
                      </li>
                      <li>
                        <span>
                          优惠减免
                          <i class="iconfont icon-question"></i>
                        </span>
                        <span>¥0</span>
                      </li>
                      <li>
                        <span>里程抵扣</span>
                        <span>¥0</span>
                      </li>
                      <li>
                        <span>邮寄费用</span>
                        <span>¥0</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="footer">
                <span></span>
                <span>¥ {{ order.orderAmount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import { orderDetail, orderCancel } from "@/api/newsapi";
import { CURRENT_ORDER } from "@/store/mutation-types";
import StickySidebar from "sticky-sidebar";
import ProgressBar from "../../components/ProgressBar";
import PopoverContent from "../../components/PopoverContent";
import FeePanel from "../../components/Book2FeePanel";
import moment from "moment";

export default {
  components: { ProgressBar, PopoverContent, FeePanel },
  data() {
    return {
      order: {},
      dialogVisible: false,
      fareData: [],
      orderNo: "",
      intervelTime: "",
      loading: true,
      orderCanPay: false,
      payType: "alipay",
    };
  },
  mounted() {
    // this.order = Vue.ls.get(CURRENT_ORDER);
    this.orderNo = this.$route.params.orderNo;
    this.getOrderDetail();

    new StickySidebar(".fee-panel", {
      topSpacing: 20,
      bottomSpacing: 20,
      containerSelector: ".passenger",
      innerWrapperSelector: ".fee-panel__inner",
    });
  },
  filters: {
    formatDate(timestamp) {
      return moment(timestamp).format("YYYY-MM-DD");
    },
    formatTime(timestamp) {
      return moment(timestamp).format("HH:mm");
    },
    formatType(type){
      let typeDesc = "";
      switch(type){
        case "ADT":
          typeDesc = "成人";
          break;
        case "CHD":
          typeDesc = "儿童";
          break;
        case "INF":
          typeDesc = "儿童";
          break;
        default:
          typeDesc = "";
          break;
      }
      return typeDesc;
    },
    formatCertType(certType){
      let certTypeDesc = "";
      switch(certType){
        case "NI":
          certTypeDesc = "身份证";
          break;
        case "PP":
          certTypeDesc = "护照";
          break;
        default:
          certTypeDesc = "";
          break;
      }
      return certTypeDesc;
    },
  },
  methods: {
    showOrder() {
      this.fareData=[];
      var adtCount = 0,
        chdCount = 0,
        infCount = 0;
      for (var i = 0; i < this.order.passengers.length; i++) {
        if (this.order.passengers[i].type == "ADT") {
          adtCount++;
        } else if (this.order.passengers[i].type == "CHD") {
          chdCount++;
        } else if (this.order.passengers[i].type == "INF") {
          infCount++;
        }
      }

      console.log(adtCount + " " + chdCount + " " + infCount);

      if (adtCount > 0) {
        var adtFare = {
          title: "成人",
          price: this.order.adultTotalPrice / adtCount,
          passCount: adtCount,
          tax: this.order.adultTotalTax / adtCount,
        };
        this.fareData.push(adtFare);
      }

      if (chdCount > 0) {
        var chdFare = {
          title: "儿童",
          price: this.order.childTotalPrice / chdCount,
          passCount: chdCount,
          tax: this.order.childTotalTax / chdCount,
        };
        this.fareData.push(chdFare);
      }

      if (infCount > 0) {
        var infFare = {
          title: "婴儿",
          price: this.order.infantTotalPrice / infCount,
          passCount: infCount,
          tax: this.order.infantTotalTax / infCount,
        };
        this.fareData.push(infFare);
      }
    },
    gotoCancelOrder(orderNo) {
      this.$confirm("当前座位紧张，请确认是否立即取消此订单？", "温馨提示", {
        distinguishCancelAndClose: true,
        center: false,
        confirmButtonText: "立即取消",
        cancelButtonText: "再等一等",
        cancelButtonClass: "cancelBtn",
        customClass: "m-message-box",
      }).then(() => {
        this.cancelOrderHandler(orderNo);
      });
    },
    cancelOrderHandler(orderNo) {
      orderCancel({ t: orderNo }).then((res) => {
        if (res.success) {
          this.getOrderDetail(1);
        }
      });
    },
    reloadPage() {
      this.dialogVisible = false;
      this.$router.push({
        path: "/order/detail/" + this.orderNo,
        query: { t: new Date().getTime() },
      });
      //this.window. reload();
    },
    payHandler() {
      console.log("支付检测");
      this.dialogVisible = true;
      window.open(
        "/route/casherpay?bank=alipay&orderNo=" + this.order.orderNo,
        "_blank"
      );
    },
    formatDuring(mss) {
      var minutes = parseInt((mss % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = (mss % (1000 * 60)) / 1000;
      seconds = parseInt(seconds);
      return minutes + " 分 " + seconds + " 秒 ";
    },
    payIntevel() {
      var that = this;
      var timeInterval = setInterval(function () {
        var remainMs = that.order.payTimeLimit - new Date().getTime();
        if (remainMs <= 0) {
          clearInterval(timeInterval);
          that.intervelTime="";
          that.orderCanPay = true;
          return;
        }
        that.intervelTime = that.formatDuring(remainMs);
      }, 1000);
    },
    getOrderDetail() {
      var request = { t: this.orderNo };
      orderDetail(request).then((res) => {
        this.loading = false;
        if (res.success) {
          this.order = res.data;
          this.showOrder();
          this.payIntevel();
        }
      });
    },
  },
};
</script>
<style scoped lang='less'>
.next-step {
  width: 100%;
  height: 53px;
  border: 0;
  background-color: #d70039;
  color: #fff;
  font-size: 16px;
  margin: 42px 0 53px;
  cursor: pointer;
}
.next-step:active {
  background-color: #d70039;
}
.add-passenger-tips {
  font-size: 14px;
  color: #9d9d9d;
}
.add-passenger-tips span {
  color: orange;
}
.add-wrapper {
  display: flex;
  align-items: center;
}
.add-passenger {
  margin-right: 10px;
  background-color: #ffffff;
  width: 163px;
  height: 43px;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.add-passenger:active {
  background-color: #d70039;
  border-color: #d90007;
}
.save-often .el-checkbox__label {
  font-size: 12px;
  color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-checked .el-checkbox__inner,
.save-often .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #bc8b0d !important;
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__inner:hover {
  border-color: #bc8b0d !important;
}
.save-often .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #bc8b0d !important;
}
.icon-question {
  font-size: 13px;
}
.form-label {
  height: 28px;
  line-height: 28px;
  font-size: 14px;
  font-weight: 700;
  color: #717070;
}
.passenger-input-info .el-form-item__label {
  padding-bottom: 0;
}
.passenger-input-info .el-form-item {
  margin-bottom: 10px;
}
.passenger-input-info .el-input__inner {
  border-radius: 0 !important;
}
.passenger .el-checkbox__input.is-checked .el-checkbox__inner,
.passenger .el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #e34c4c;
  border-color: #e34c4c;
}
.el-checkbox__label {
  padding-left: 5px;
}
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #e34c4c;
}
.el-checkbox__inner:hover {
  border-color: #e34c4c;
}
.el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #e34c4c;
}
</style>
<style scoped lang='less'>
.book-panel {
  width: 100%;
  position: relative;
  div {
    width: 50px;
    background-color: #d70039 !important;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 10px;
  }
}
.fee-panel-body .footer {
  padding: 0 26px;
  height: 60px;
  font-weight: 700;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // border-top: 1px solid #bfbfbf;
}
.fee-panel-body .footer span:nth-child(2) {
  font-size: 30px;
  color: #d70039;
}
h4 {
  height: 34px;
  font-size: 14px;
  font-weight: 700;
  line-height: 34px;
  color: #4d4e4e;
}
/* .noBefore {
  padding: 16px 0 0 10px;
  border-top: 1px solid #bcbcbd;
} */
.noBefore::before {
  content: none;
  width: 0 !important;
}
.fee-panel-group ul li {
  height: 26px;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  color: #4d4e4e;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
/* .fee-panel-group ul::before {
  position: absolute;
  top: 6px;
  left: 0;
  bottom: 6px;
  width: 2px;
  content: "";
  background-color: #d8d8d8;
} */
.fee-panel-group ul {
  position: relative;
  padding-left: 10px;
}
.fee-panel-group > div {
  margin-bottom: 10px;
}
.fee-panel__inner {
  background: #ffffff;
  // border: 1px solid #eae7e7;
}
.fee-panel-content {
  padding: 16px;
  background-color: #fff;
}
.fee-panel-body {
  // max-height: 485px;
  overflow-y: auto;
  /* background-color: #f8f8f8; */
}
.fee-panel-header {
  height: 60px;
  line-height: 60px;
  color: #4d4e4e;
  font-size: 16px;
  font-weight: 700;
  background-color: #f8f8f8;
  border-bottom: 1px solid #ededed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
}
.tips-text {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.65);
}
.passenger-input-info .el-input,
.passenger-input-info .el-select {
  width: 287px;
}
.no-padding {
  padding: 0 16px !important;
}
.form-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-title div:first-child {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  color: #b2b2b2;
  text-align: left;
}
.form-title div:nth-child(2) {
  margin: 0 4px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #9d9d9d;
  text-align: center;
  cursor: pointer;
}
.check-item {
  height: 32px;
  line-height: 32px;
}
.passenger-info {
  margin-bottom: 16px;
  background-color: #fff;
  padding: 8px 0;
  padding-left: 5%;
  line-height: 30px;
  /* border: 1px solid #eae7e7; */
}
.booked-ticket + .booked-ticket {
  margin-top: 20px;
}
.booked-flight-rule {
  width: 15%;
}
.booked-flight-rule span {
  width: 80px;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  color: #e34c4c;
  text-align: center;
  background-color: #fff;
  border: 1px solid #e34c4c;
  border-radius: 2px;
  transition: all, 0.68s;
  display: inline-block;
  margin: 0;
  padding: 0;
  height: 25px;
}
.booked-flight-cabin {
  width: 15%;
}
.booked-flight-cabin span:first-child {
  margin-right: 8px;
}
.booked-flight-arr {
  width: 20%;
}
.booked-flight-arr p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-arr p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.booked-flight-arr p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.booked-flight-duration p:first-child {
  margin-top: 23px;
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration p:nth-child(2) {
  line-height: 15px;
}
.booked-flight-duration p:last-child {
  height: 17px;
  line-height: 17px;
}
.booked-flight-duration {
  width: 15%;
  padding: 0 10px;
  font-size: 12px;
  text-align: center;
}
.booked-flight-dep {
  text-align: right;
  width: 20%;
}
.booked-flight-dep p:first-child {
  margin-top: 10px;
  height: 25px;
  font-size: 18px;
  font-weight: 700;
  line-height: 25px;
}
.booked-flight-dep p:nth-child(2) {
  height: 20px;
  line-height: 20px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.booked-flight-dep p:last-child {
  height: 17px;
  font-size: 12px;
  line-height: 17px;
}
.qucheng {
  margin-left: 4px;
  color: #fff;
}
.booked-ticket-panel > div {
  line-height: 85px;
}
.booked-flight-type {
  color: #fff;
  background-color: #e34c4c;
  border: 1px solid #d31a1a;
  width: 15%;
  text-align: center;
  left: -17px !important;
  position: absolute;
}
.booked-flight-type p:first-child {
  margin-top: 10px;
  height: 20px;
  line-height: 20px;
}
.booked-flight-type p:last-child span {
  font-size: 16px;
  font-weight: 700;
}
.booked-flight-type p:last-child {
  margin-top: 10px;
  height: 22px;
  line-height: 22px;
}
.booked-ticket-panel {
  margin-bottom: 16px;
  background-color: #fff;
  border: 1px solid #eae7e7;
  display: flex;
  height: 85px;
}
.booked-ticket-body {
  /* padding: 0 18px; */
  font-size: 14px;
}
.passenger {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.info-panel {
  width: 756px;
  position: relative;
}
.fee-panel {
  width: 428px;
}
.booked-ticket {
  background: #ffffff;
  border: 1px solid #ededed;
  border-radius: 10px;
}
.booked-title {
  height: 42px;
  box-sizing: border-box;
  padding: 0 18px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.title {
  font-weight: 700;
  color: #515457;
  font-size: 20px;
}
.research {
  padding: 0;
  width: 90px;
  height: 26px;
  font-size: 12px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  text-align: center;
  background-color: #d70039;
  border-color: #ff0505;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.item-title {
  color: #929a9f;
  font-size: 14px;
  width: 70px;
  display: inline-block;
}
.item-index {
  color: #929a9f;
  font-size: 16px;
  font-style: italic;
  margin-right: 10px;
}
.item-value {
  color: #515457;
  font-size: 14px;
  display: inline-block;
}
.order-pass-item {
  display: flex;
  // justify-content: flex-start;
  align-items: center;
  position: relative;
  width: 560px;
  flex-wrap: wrap;
  &>div{
    width: 220px;
  }
}
.order-pass-item div {
  margin-right: 30px;
}
.order-wrapper {
  /* background: f7f7f7 !important; */
}
.order-footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.order-success,
.order-failed {
  width: 48%;
  padding: 2px 5px;
  background: #d70039;
  border: 1px solid #d70039;
  color: #fff;
  border-radius: 5px;
  height: 40px;
  display: inline-block;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
.order-failed {
  background: #ffffff;
  color: #333333;
  border: 1px solid #d70039;
}
.info-text {
  min-height: 300px;
  padding: 10px !important;
  &-title {
    font-size: 22px;
    font-weight: bold;
    display: block;
    color: #929a9f;
  }
  &-content {
    line-height: 46px;
  }
  .warning {
    color: #d70039;
  }
}
.radio-type {
  display: flex !important;
  padding-left: 40px;
  img {
    margin-top: 25px;
  }
}
.step-disabled{
  background-color: #ccc;
}
</style>